import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
//import Obfuscate from "react-obfuscate"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Team = () => {
    const data = useStaticQuery(graphql`
        query EnergyData {
            allEnergyJson {
                nodes {
                    id
                    email
                    name
                    repOffice
                    persons {
                        description
                        email
                        mobileNumber
                        name
                        officeNumber
                        position
                        bio
                        img {
                            childImageSharp {
                                gatsbyImageData(aspectRatio: 1)
                            }
                        }
                    }
                }
            }
        }
    `)

    const locations = data.allEnergyJson.nodes

    return (
        <Layout>
            <Seo title="Team" />

            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Team</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {locations.map(location => {
                            return (
                                <Fragment key={location.id}>
                                    <div className="row">
                                        <div className="col d-flex align-items-center">
                                            <h2>{location.name}</h2>
                                            <span className="ms-3 mb-2">{location.repOffice ? "(Rep. Office)" : ""}</span>
                                        </div>
                                    </div>
                                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3 g-md-5 mb-3">
                                        {location.persons.map((person, idx) => {
                                            return (
                                                <Fragment key={idx}>
                                                    <div className="col">
                                                        <div className="card h-100">
                                                            <GatsbyImage
                                                                className="card-img-top mh-50"
                                                                image={getImage(person.img)}
                                                                alt={person.name}
                                                            />
                                                            <div className="card-body">
                                                                <h5
                                                                    className="card-title"
                                                                    type="button"
                                                                    data-bs-toggle="offcanvas"
                                                                    data-bs-target={`#team` + location.id + idx}
                                                                    aria-controls="offcanvasBottom"
                                                                >
                                                                    {person.name}
                                                                </h5>

                                                                <p className="card-text">{person.position}</p>
                                                                {/* {person.officeNumber && (
                                                                    <div>
                                                                        <FontAwesomeIcon
                                                                            className="me-3"
                                                                            fixedWidth
                                                                            icon={["far", "phone"]}
                                                                        />
                                                                        <Obfuscate tel={person.officeNumber} />
                                                                    </div>
                                                                )}
                                                                {person.mobileNumber && (
                                                                    <div>
                                                                        <FontAwesomeIcon
                                                                            className="me-3"
                                                                            fixedWidth
                                                                            icon={["far", "mobile-alt"]}
                                                                        />
                                                                        <span>
                                                                            <Obfuscate tel={person.mobileNumber} />
                                                                        </span>
                                                                    </div>
                                                                )}
                                                                {person.email && (
                                                                    <div className="">
                                                                        <FontAwesomeIcon
                                                                            className="me-3"
                                                                            fixedWidth
                                                                            icon={["far", "envelope"]}
                                                                        />
                                                                        <Obfuscate className="text-break" email={person.email} />
                                                                    </div>
                                                                )}
                                                                {location.email && (
                                                                    <div className="">
                                                                        <FontAwesomeIcon
                                                                            className="me-3"
                                                                            fixedWidth
                                                                            icon={["far", "envelope"]}
                                                                        />
                                                                        <Obfuscate className="text-break" email={location.email} />
                                                                    </div>
                                                                )} */}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="offcanvas offcanvas-bottom"
                                                            tabIndex="-1"
                                                            id={`team` + location.id + idx}
                                                            aria-labelledby="offcanvasBottomLabel"
                                                        >
                                                            <div className="offcanvas-header">
                                                                <h5 id="offcanvasBottomLabel">{person.name}</h5>
                                                                <button
                                                                    type="button"
                                                                    className="btn-close text-reset"
                                                                    data-bs-dismiss="offcanvas"
                                                                    aria-label="Close"
                                                                ></button>
                                                            </div>
                                                            <div className="offcanvas-body">
                                                                <p>{person.bio}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )
                                        })}
                                    </div>
                                </Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Team
